import logo from './logo.svg';
import './App.css';
import React, { lazy, Suspense, useEffect, useState } from "react";
import './config';
import $ from 'jquery';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ReactSession } from 'react-client-session';
import { gapi } from "gapi-script";

const loader = document.querySelector("#preloader");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--show");

const hideLoader = () => loader.classList.add("loader--hide");

function App() {
  const [themes, setThemes] = useState("");
  ReactSession.setStoreType("localStorage");

  useEffect(() => {
    const icon = document.querySelector('link[rel="icon"]');

    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: "203471354608-gognnrj3fe89bi5hf5451rplevpuil2s.apps.googleusercontent.com",
        scope: "",
      });
    });


    axios.get(global.config.host + '/themes')
      .then(res => {
        if (res.data.status == "success") {
          // $("title").html(res.data.title);

          global.config.theme = res.data.theme;
          global.config.title = res.data.title;
          global.config.backend = global.config.host + '/' + res.data.theme;

          icon.setAttribute('href', global.config.backend + "/api/getimage/" + "L2Fzc2V0cy9pbWcvbG9nby5wbmc=");
          
          
          const HeaderLazy = React.lazy(() => import('./themes/' + res.data.theme + '/Header.js'));
          const ContentLazy = React.lazy(() => import('./themes/' + res.data.theme + '/Content.js'));
          const FooterLazy = React.lazy(() => import('./themes/' + res.data.theme + '/Footer.js'));


          setThemes(
            <Suspense fallback={showLoader}>
              <BrowserRouter basename={'/' + global.config.subdomain}>
                <HeaderLazy hideloader={hideLoader} />
                <ContentLazy />
                <FooterLazy theme={res.data.theme} />
              </BrowserRouter>
            </Suspense>);
        } else {
          alert("Error get data from backend !!");
        };
      }).catch(res => {
        console.log(res);
        Swal.fire({
          title: "Error Connect",
          text: "Error connect to services",
          icon: "warning"
        });
      })
  }, [])

  return (
    <>
      {themes}
    </>
  );
}

export default App;
